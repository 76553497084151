import React from "react"
import Seo from '../components/seo'
import DefaultHero from "../components/DefaultHero"

function DefaultTemplate({ pageContext, children, ...rest }) {
  return (
    <>
      <Seo title={pageContext.frontmatter.title} />
      <DefaultHero title={pageContext.frontmatter.title} />
      <div className="container mx-auto pb-16">
        <div className="prose max-w-full">
          {children}
        </div>
      </div>
    </>
  )
}

export default DefaultTemplate
