import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/workspace/src/templates/default.js";
import pdfLink from "../images/nmc-code-of-conduct-1-0.pdf";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="max-w-3xl">
      <h2 {...{
        "id": "company-mission",
        "style": {
          "position": "relative"
        }
      }}>{`COMPANY MISSION`}<a parentName="h2" {...{
          "href": "#company-mission",
          "aria-label": "company mission permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p">{`We create platforms`}</strong>{` that provide Knowledge and Services to help businesses
succeed in their ventures in the digital era. We do this with a talented pool of people
with a growth mindset who can build platforms that empower our clients in
conducting better business and consequently achieve better results.`}</p>
      <p>{`Through this `}<strong parentName="p">{`Code of Conduct`}</strong>{` we want to showcase our values and beliefs and
ensure that these values get used by everyone joining NMC Soft.`}</p>
      <p><strong parentName="p">{`Our company’s suppliers`}</strong>{` shall put into place safe working conditions and ensure
their employees are treated with respect. Also their production processes should
be responsible and environmentally friendly.`}</p>
      <p><strong parentName="p">{`Our suppliers are selected and evaluated`}</strong>{` beyond the basis of economic criteria.
We also look closely at environmental protection, compliance with human rights,
labor and social standards as well as anti-discrimination and anti-corruption.`}</p>
      <h2 {...{
        "id": "purpose-of-the-code-of-conduct",
        "style": {
          "position": "relative"
        }
      }}>{`PURPOSE OF THE CODE OF CONDUCT`}<a parentName="h2" {...{
          "href": "#purpose-of-the-code-of-conduct",
          "aria-label": "purpose of the code of conduct permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p">{`The purpose of this Code of Conduct`}</strong>{` is to conduct the business of the Company in
accordance with the applicable laws, regulations, rules and with the highest
standard of ethics and values. The matters covered in this Code are of utmost
importance to the Company, shareholders and employees.`}</p>
      <p>{`Our Supplier Code of Conduct enlists our Supplier Partners in upholding these
same principles. We believe this joint commitment to ethical conduct and integrity
is a strong foundation for trusted business relationships that create shared value.`}</p>
      <h2 {...{
        "id": "our-set-of-values-and-beliefs-include",
        "style": {
          "position": "relative"
        }
      }}>{`Our Set of Values and beliefs include:`}<a parentName="h2" {...{
          "href": "#our-set-of-values-and-beliefs-include",
          "aria-label": "our set of values and beliefs include permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p"><em parentName="strong">{`We stick to our word and keep our promise`}</em></strong><br parentName="p"></br>{`
`}{`We understand that our job starts when we promise to have something
done. When we give our word to someone that one project is going to be
implemented to the best quality and on a certain timeframe. So, we hire
people with integrity who stick to their word and keep their promises.
This determination to stick to our word is what differentiates us in our
business and everyday life as people.`}</p>
      <p>{`This applies to relationships with our clients, team members and
suppliers / partners. `}</p>
      <p><strong parentName="p"><em parentName="strong">{`Great Communication is the key`}</em></strong><br parentName="p"></br>{`
`}{`We Communicate, we don’t give orders. Great communication implies
being kind to answer to every request made verbally or in writing. No
communication should be left unanswered as we believe that is a sign
of respect and part of the process in having more context into situations
that improve decision making and gets the work done on time. `}</p>
      <p>{`We are aware that the services that we offer or the job that we do can
also be provided from other companies similar to us, but great
communication between us and the client and treating our clients as
friends makes an important difference for a long lasting relationship. `}</p>
      <p>{`Great communication is necessary between colleagues, suppliers and
clients.`}</p>
      <h2 {...{
        "id": "compliance-with-laws-or-laws-relevant-to-employee-or-member-rights",
        "style": {
          "position": "relative"
        }
      }}>{`COMPLIANCE WITH LAWS OR LAWS RELEVANT TO EMPLOYEE OR MEMBER RIGHTS`}<a parentName="h2" {...{
          "href": "#compliance-with-laws-or-laws-relevant-to-employee-or-member-rights",
          "aria-label": "compliance with laws or laws relevant to employee or member rights permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p">{`Our company`}</strong>{` is regulated by the laws of the Republic of Albania and all the rights of the employees
and members of the company are protected by the Albanian law. There are specific laws for each
field of treatment, where we can enforce the Labor Code for employees or The company law for
members of the company. `}</p>
      <p>{`Also, in `}<strong parentName="p">{`our company`}</strong>{` are applied the international conventions and laws ratified by the Republic of
Albania, which in certain fields protect the rights of the employees and the members
of the company. We strive to implement all laws and regulations, in order to
provide a comfortable environment for our employees and a clear vision
for our company.`}</p>
      <p>{`As a `}<strong parentName="p">{`responsible company`}</strong>{`, we expect our global Supplier Partners to share
our commitment to following the law. We expect our Supplier Partners
to know and follow the laws that apply to them and their business and
treat legal requirements as a minimum standard.`}</p>
      <h2 {...{
        "id": "outline-of-unacceptable-behaviors-in-the-workplace-and-their-consequences",
        "style": {
          "position": "relative"
        }
      }}>{`OUTLINE OF UNACCEPTABLE BEHAVIORS IN THE WORKPLACE AND THEIR CONSEQUENCES`}<a parentName="h2" {...{
          "href": "#outline-of-unacceptable-behaviors-in-the-workplace-and-their-consequences",
          "aria-label": "outline of unacceptable behaviors in the workplace and their consequences permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p">{`Everyone is obliged to respect each other in the company`}</strong>{`, no one should
be discriminated against because of race, religion, ethnicity, political,
religious or philosophical beliefs, economic status, social status or
parental affiliation. The values of the company and its image must
be respected by all employees and members of the company.`}</p>
      <p><strong parentName="p">{`Employees must respect work ethic that means that insulting`}</strong>{`, offensive words that offend and
cause concern to colleagues are prohibited on company premises. In any such case, punitive
measures and reprimands with warning for dismissal will be taken. Everyone should be professional
in their relationship with each other and with the company's clients. Everyone should be able to
separate personal issues from those of work without hindering the smooth running of the work and
the atmosphere of the company.`}</p>
      <p><strong parentName="p">{`We expect all the Members of the Company and Employees`}</strong>{` to act in accordance with the
highest standards of personal and professional integrity, honesty and ethical conduct, while
working at the Company’s premises, at offsite locations, at Company’s sponsored business and
social events, and/or at any other place where the Members of the Company and Employees
represent the Company.`}</p>
      <p><strong parentName="p">{`We expect from all Members of the Company and Employees to be punctual, be honest, transparent, be friendly and smile.`}</strong></p>
      <h2 {...{
        "id": "protecting-confidential-information",
        "style": {
          "position": "relative"
        }
      }}>{`PROTECTING CONFIDENTIAL INFORMATION`}<a parentName="h2" {...{
          "href": "#protecting-confidential-information",
          "aria-label": "protecting confidential information permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p">{`Members of the Company and Employees`}</strong>{` are required to maintain the confidentiality of all confidential information that they receive or become privy to in connection with the Company's business,
except when disclosure is authorized or legally mandated. Confidential information includes all
nonpublic information that might prejudice the ability of the Company to pursue certain objectives,
be of use to competitors or harm the Company, its suppliers or its advertisers, if disclosed.`}</p>
      <p><strong parentName="p">{`Confidential information also includes`}</strong>{` any information relating to the Company's business and
affairs that results in or would reasonably be expected to result in a significant change in the
market value of the Company's securities or any information a reasonable investor would consider
important in making an investment decision. Members of the Company and Employees must not
use confidential information for their own advantage or profit directly or indirectly.`}</p>
      <h2 {...{
        "id": "conduct-business-with-integrity",
        "style": {
          "position": "relative"
        }
      }}>{`CONDUCT BUSINESS WITH INTEGRITY`}<a parentName="h2" {...{
          "href": "#conduct-business-with-integrity",
          "aria-label": "conduct business with integrity permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p><strong parentName="p">{`We seek business relationships based on trust, transparency, and mutual accountability.`}</strong></p>
      <p><strong parentName="p">{`We expect our Supplier Partners`}</strong>{` to compete fairly and ethically for Company’s business,
never offer or accept bribes, kickbacks, inappropriate gifts or hospitality, or other
improper incentives in connection with Company’s business, avoid any conflict of interest
relating to financial interests or other arrangements with our employees that may be
considered inappropriate and work with their own suppliers to promote business
conduct consistent with the principles in this Code.`}</p>
      <p><strong parentName="p">{`We work with Supplier Partners`}</strong>{` who share our desire to build productive business
relationships. This requires honest communications, mutual respect, and delivering on
commitments.`}</p>
      <p><strong parentName="p">{`We expect our Supplier Partners`}</strong>{` to Share our commitment to conducting business
honestly and transparently, and also to honor business obligations and manage
unanticipated events in a proactive, timely, and open way.`}</p>
      <h2 {...{
        "id": "treat-people-with-dignity-and-respect",
        "style": {
          "position": "relative"
        }
      }}>{`TREAT PEOPLE WITH DIGNITY AND RESPECT`}<a parentName="h2" {...{
          "href": "#treat-people-with-dignity-and-respect",
          "aria-label": "treat people with dignity and respect permalink",
          "className": "inline-block ml-1 relative top-1 after"
        }}><svg parentName="a" {...{
            "xmlns": "http://www.w3.org/2000/svg",
            "height": "24",
            "viewBox": "0 0 24 24",
            "width": "24"
          }}><path parentName="svg" {...{
              "d": "M0 0h24v24H0z",
              "fill": "none"
            }}></path><path parentName="svg" {...{
              "d": "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            }}></path></svg></a></h2>
      <p>{`We expect our Supplier Partners to stand with us in prioritizing the safety,
well-being, and dignity of all individuals, whose talents and hard work help us
deliver our products and services.`}</p>
      <p>{`We expect our Supplier Partners to:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Provide safe`}</strong>{` and healthy working conditions at all of their operations `}</li>
        <li parentName="ul"><strong parentName="li">{`Never use`}</strong>{` or tolerate the use of human trafficking, forced labor, or child labor as defined by the International Labour Organization (ILO)`}</li>
        <li parentName="ul"><strong parentName="li">{`Foster`}</strong>{` an inclusive work environment that is free of harassment and discrimination`}</li>
        <li parentName="ul"><strong parentName="li">{`Respect`}</strong>{` employees’ rights to organize and bargain collectively`}</li>
        <li parentName="ul"><strong parentName="li">{`Meet`}</strong>{` or exceed all legal requirements for compensation and working conditions `}</li>
      </ul>
      <p>{`Code of Conduct `}<a href={pdfLink}>{`pdf version`}</a>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      