import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function DefaultHero({ title }) {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "default-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="bg-white relative overflow-hidden">
      <div className="absolute right-0 bottom-0 top-0 left-0 pointer-events-none flex items-center">
        <div className="w-full mx-auto max-w-screen-2xl opacity-25 md:opacity-75">
          <GatsbyImage
            alt=""
            image={data.background.childImageSharp.gatsbyImageData}
            className="w-full h-auto"
            style={{
              maxWidth: 300,
            }}
          />
        </div>
      </div>
      <div className="relative h-full flex flex-col">
        <div className="container flex-1 flex flex-col justify-center py-12 md:py-20">
          <h1 className="text-4xl sm:text-4.5xl md:text-6xl leading-tight font-medium text-gray-900">
            {title}
          </h1>
        </div>
      </div>
    </div>
  )
}

export default DefaultHero
